import React from 'react';
import './App.css';

import Home from './pages/Home';
import Sale from './pages/Sale';
import DetailPageSale from './pages/DetailPageSale';
import Error from './pages/Error';

import {Route, Switch} from 'react-router-dom';

import NavBar from './components/NavBar';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';
import Contact from './pages/Contact';
import OuP from './pages/OuP';
import UkS from './pages/UkS';
import Pp from './pages/Pp';
import Onama from './pages/AboutUs';


function App() {
  return (
    <>
      <ScrollToTop/>
      <NavBar/>
      <Switch>
      <Route exact path="/" component={Home}/>
      <Route exact path="/nekretnine/" component={Sale}/>
      <Route exact path="/kontakt/" component={Contact}/>
      <Route exact path="/nekretnine/:slug" component={DetailPageSale}/>
      <Route exact path="/onama/" component={Onama}/>
      <Route exact path="/oup/" component={OuP}/>
      <Route exact path="/uks/" component={UkS}/>
      <Route exact path="/pp/" component={Pp}/>
      <Route exact path="/kontakt/" component={Contact}/>
      <Route component={Error}/>
      </Switch>
      <Footer/>
    </>
  );
}

export default App;
