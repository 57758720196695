import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import defaultImg from '../images/room-1.jpeg';

export default function RealEstateSale({realEstate}) {
    const{name, slug, images, price} = realEstate
    return (
        <article className="realestate">
            
            <div className="img-container">
                <img src={images[0] || defaultImg} alt="single realestate"/>
                <div className="price-top">
                   <h6>{price} €</h6>  
                </div>
                <Link to={`/nekretnine/${slug}`} className="btn-primary realestate-link">Saznaj više</Link>
            </div>
            <p className="realestate-info">{name}</p>
        </article>
    
    );
}

RealEstateSale.propTypes = {
   realEstate: PropTypes.shape({
       name:PropTypes.string.isRequired,
       slug:PropTypes.string.isRequired,
       images:PropTypes.arrayOf(PropTypes.string).isRequired,
       price:PropTypes.number.isRequired
   })
}
