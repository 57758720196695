import React from 'react';


const NewService = () => {
    return (
        <div className="service-container">
            <p className="mission">Naša misija</p>
            <p>Pomažemo vam da pronađete <i>nekretninu</i>. Promovišemo prave vrednosti</p>
        </div>
    )
}

export default NewService;