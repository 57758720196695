import React, { Component } from 'react';
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import {Helmet} from 'react-helmet';


//import ImageGallery from 'react-image-gallery';

class Contact extends Component {
    render() {
        const position =[43.049553, 19.771512] ; 
        return (
            <>
            <Helmet>
                <title>Kontaktirajte nas | Solar Nekretnine</title>
                <meta name="description" content="Kontaktirajte nas | Solar Nekretnine | Bulevar Svetog Petra Cetinjskog b.b" />
                <link rel="canonical" href="https://solarnekretnine.com/kontakt" />
            </Helmet>
            <section className="contact">
                <div className="contact-map">
                  <article className="map-info">
                  <h1>Kontaktirajte nas</h1><br/><br/>
                  <h3>Bulevar Svetog Petra Cetinjskog b.b</h3>
                  <h6>Telefon1: +382 69 189 000</h6>
                  <h6>Telefon2: +382 69 333 992</h6>
                  <h6>Email: solarnekretnine@gmail.com</h6>
                  </article>
                  <article className="maps2">
                  <MapContainer center={position} zoom={17} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                   <Marker position={position}>
                   <Popup>
                        SOLAR SYSTEM
                   </Popup>
                   </Marker>
                   </MapContainer>
                  </article>
                </div>
            </section>
            </>
        );
    }
}

export default Contact;