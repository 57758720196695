import React, { Component } from 'react';
import {RealEstateContext} from '../context';
import Loading from './Loading';
import RealEstateSale from './RealEstateSale';
import Title from './Title';
import Advertise from '../components/Advertise';

class FeaturedRE extends Component {
    static contextType = RealEstateContext;
    render() {
        let {loading, featuredRESale : realEstatesSale} = this.context;
        realEstatesSale = realEstatesSale.map(realEstateSale => {
            return <RealEstateSale key={realEstateSale.id} realEstate={realEstateSale}/>
        });

        let {loading2, featuredRERent : realEstatesRent} = this.context;
        realEstatesRent = realEstatesRent.map(realEstateRent => {
            return <RealEstateSale key={realEstateRent.id} realEstate={realEstateRent}/>
        })
        return (
        <section className="featured-realestate">
            <Title title="Istaknute nekretnine za prodaju"/>
            <div className="featured-realestate-center">
                {loading?<Loading/>:realEstatesSale}   
            </div>
            <br/><br/><br/>
            <Advertise/>
            <br/><br/><br/>
            <Title title="Istaknute nekretnine za izdavanje"/>
            <div className="featured-realestate-center">
                {loading2?<Loading/>:realEstatesRent}   
            </div>
        </section>
        );
    }
}

export default FeaturedRE; 