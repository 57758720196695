import React, { Component } from 'react';
// import items from './data';
import Client from './Contentful';
const RealEstateContext = React.createContext();
// <RealEstateContext.Provider value={'hello'}
class RealEstateProvider extends Component {
     state={
         realEstates:[],
         sortedRE:[],
         featuredRESale:[],
         featuredRERent:[],
         saleRE: [],
         loading:true,
         type:'Izaberite',
         location:'Izaberite',
         capacity:1,
         price:0,
         minPrice:0,
         maxPrice:0,
         minSize:0,
         maxSize:0,
         purpose: 'Izaberite',
     };
     // getData

     getData = async () =>{
         try {
             let response = await Client.getEntries({
                content_type: "solarNekretnine"

             });
             let realEstates = this.formatData(response.items);
             let featuredRESale = realEstates.filter(realEstate => realEstate.featuredSale === true);
             let featuredRERent = realEstates.filter(realEstate => realEstate.featuredRent === true);
             let maxPrice = Math.max(...realEstates.map(item => item.price));
             let maxSize = Math.max(...realEstates.map(item => item.size));

             this.setState({
                 realEstates,
                 featuredRESale,
                 featuredRERent,
                 sortedRE: realEstates, 
                 loading:false,
                 price: maxPrice,
                 maxPrice,
                 maxSize,
         });

         } catch (error) {
             console.log(error);
         }
     }

     componentDidMount(){
         this.getData()
         
     }

     formatData(items){
         let tempItems = items.map(item => {
             let id = item.sys.id;
             let images = item.fields.images.map(image => image.fields.file.url);
             let sale = item.fields.sale;
             
             let realEstate = {...item.fields,images,id, sale};
             return realEstate;
         });
         return tempItems;
     }

     getRealEstate = (slug) =>{
         let tempRealEstates = [...this.state.realEstates];
         const realEstate = tempRealEstates.find(realEstate => realEstate.slug===slug);
         return realEstate;
     }
     
     handleChange = event => {
       const target = event.target;
       const value = target.type === 'checkbox' ? target.checked:target.value;
       const name = event.target.name;
       this.setState({
          [name]:value 
       },this.filterRealEstates)
     };
     filterRealEstates = () => {
          let{
              realEstates,purpose, type, capacity,location, price, minSize, maxSize,breakfast
          } = this.state
          //all the realEstates
          let tempRealEstates = [...realEstates];
          //transform value
          capacity = parseInt(capacity);
          price = parseInt(price);
           //filter by purpose
         if(purpose !== 'Izaberite'){
            tempRealEstates = tempRealEstates.filter(realEstate => realEstate.purpose === purpose);
         }
          //filter by location
          if(location !=='Izaberite'){
              tempRealEstates = tempRealEstates.filter(realEstate => realEstate.location === location);
          }

          //filter by type
          if(type !== 'Izaberite'){
             tempRealEstates = tempRealEstates.filter(realEstate => realEstate.type === type);
          }

          //filter by capacity
          if(capacity !==1){
             tempRealEstates = tempRealEstates.filter(realEstate => realEstate.capacity >= capacity); 
          }
          // filter by price
          tempRealEstates = tempRealEstates.filter(realEstate => realEstate.price <= price);

          // filter by size

          tempRealEstates = tempRealEstates.filter(realEstate => realEstate.size >= minSize && realEstate.size <= maxSize);

          // filter by breakfast
            if(breakfast){
            tempRealEstates = tempRealEstates.filter(realEstate => realEstate.breakfast === true);
            }
          // change state
          this.setState({
             sortedRE:tempRealEstates 
          })
     };

    render() {
        return (
            <RealEstateContext.Provider 
            value={{...this.state, 
                    getRealEstate: this.getRealEstate, 
                    handleChange:this.handleChange
                  }}>
                {this.props.children}
            </RealEstateContext.Provider> 
        );
    }
}

const RealEstateConsumer = RealEstateContext.Consumer;

export function withRealEstateConsumer(Component){
   return function ConsumerWrapper(props){
      return <RealEstateConsumer>
          {value => <Component {...props} context={value}/>}
      </RealEstateConsumer>
   } 
}

export {RealEstateProvider, RealEstateConsumer, RealEstateContext};