import React from 'react'
import Hero from '../components/Hero';
import Banner from '../components/Banner';
import {Link} from 'react-router-dom';
import NewService from '../components/NewService';
import FeaturedRE from '../components/FeaturedRE';
import {Helmet} from 'react-helmet';


export default function Home() {
    return (
        <>
    <Helmet>
        <title>Pronađite vaš dom iz snova | Solar Nekretnine</title>
        <meta name="description" content="Pronađite vaš dom iz snova | Solar Nekretnine | Mesto odabranih nekretnina" />
        <link rel="canonical" href="https://solarnekretnine.com/" />
    </Helmet>
        <Hero>
            <Banner title="Sanjajte. Tražite. Živite." subtitle="SOLAR NEKRETNINE" className="neons">
                <Link to='/nekretnine' className="btn-primary-beat">
                    ZAPOČNITE PRETRAGU
                </Link>
            </Banner>
        </Hero>
        <NewService/>
        
        {/*<Services/>*/}
        <FeaturedRE/>
        </>
    )
}
