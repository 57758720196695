import React from 'react';
import RealEstateSale from './RealEstateSale';


export default function RESaleList({realEstates}) {
    if(realEstates.length === 0){
        return (
            <div className="empty-search">
                <h3>Niste izabrali nijedan parametar pretrage</h3>
            </div>
        ) 
        } 
        return (
        <section className="realestatelist">
           <div className="realestatelist-center">
            {
               realEstates.map(item =>{
                  return <RealEstateSale key={item.id} realEstate={item}/>;
               })
            }
           </div>
        </section> 
        );
      }
