import React, { Component } from 'react';
import {Helmet} from 'react-helmet';



class OuP extends Component {
    render() {
        return (
            <>
            <Helmet>
                <title>Opšti uslovi poslovanja | Solar Nekretnine</title>
                <meta name="description" content="Opšti uslovi poslovanja" />
                <link rel="canonical" href="https://solarnekretnine.com/oup" />
            </Helmet>
            <div className="hero-policy">
                <div className="banner-policy">
                    <h3>Opšti uslovi poslovanja</h3>
                </div>
            </div>
            <div className="banner-policy-text">
            <p>SOLAR SYSTEM Beograd, Baba Višnjina 38 , Beograd, MB: 20564695, PIB: 106255615, šifra delatnosti: 6831, 

red. br.u Registru posrednika MTTT: 416<br/><br/><br/><br/>

 

<b>I OPŠTE ODREDBE</b><br/><br/>

Opštim uslovima poslovanja West Properties d.o.o. Beograd uređuje se poslovni odnos između posrednika u prometu i zakupu nepokretnosti i nalogodavca (fizičkog ili pravnog lica). Zaključenjem ugovora o posredovanju, odnosno prihvatanjem ovih uslova Nalogodavac potvrđuje da je upoznat i saglasan sa odredbama Opštih uslova poslovanja posrednika u prometu i zakupu nepokretnosti.<br/><br/><br/><br/>

 

<b>II OPIS POSLOVA KOJE JE POSREDNIK DUŽAN DA OBAVI</b><br/><br/>

• da zaključi Ugovor o posredovanju sa Nalogodavcem u pisanom obliku,<br/><br/>

• da nastoji da nađe i dovede u vezu sa Nalogodavcem lice radi zaključenja pravnog posla,<br/><br/>

• da da Nalogodavcu objektivno mišljenje o ceni nepokretnosti ili iznosu zakupnine nepokretnosti u skladu sa njenim karakteristikama, prilikama na tržištu, kao i drugim relevantnim okolnostima,<br/><br/>

• da izvrši uvid u isprave kojima se dokazuje pravo svojine ili drugo stvarno pravo na nepokretnosti čiji promet, odnosno zakup je predmet posredovanja i da upozori Nalogodavca naročito na moguće rizike u vezi sa upisom predmetne nepokretnosti u registre nepokretnosti, upisana prava, odnosno  terete na predmetnoj nepokretnosti, postojanje prava preče kupovine i ograničenje u pravnom prometu u skladu sa posebnim propisima,<br/><br/>

• da obavi potrebne radnje u cilju predstavljanja (prezentacije) nepokretnosti na tržištu, da postavi oglas u vezi sa prometom, odnosno zakupom nepokretnosti na odgovarajući način i da izvrši sve druge radnje dogovorene Ugovorom o posredovanju koje prelaze uobičajenu prezentaciju, a za šta ima pravo na posebne, unapred iskazane troškove,<br/><br/>

• da omogući pregled nepokretnosti,<br/><br/>

• da čuva podatke o ličnosti Nalogodavca, a po pisanom nalogu Nalogodavca da čuva kao poslovnu tajnu podatke o nepokretnosti, u vezi sa čijim prometom, odnosno zakupom posreduje, ili u vezi sa tom nepokretnosti, ili o poslu za koji posreduje,<br/><br/>

• da obavesti Nalogodavca o svim okolnostima  značajnim za predmetni posao koje su mu poznate,<br/><br/>

• da posreduje u pregovorima i nastoji da dođe do zaključenja Ugovora,<br/><br/>

• da prisustvuje kod zaključenja pravnog posla (Predugovora i Ugovora),<br/><br/>

• da prisustvuje primopredaji nepokretnosti.<br/><br/><br/><br/>

 

Smatra se da je Posrednik omogućio Nalogodavcu vezu s drugim licem (fizičkim ili pravnim) o pregovaranju za zaključenje pravnog posla, ako je Nalogodavcu omogućeno stupanje u vezu sa drugim licem sa kojim je pregovarao za zaključenje pravnog posla, a posebno ako je:<br/><br/>

• neposredno odveo ili uputio Nalogodavca ili treće lice na razgledanje predmetne nepokretnosti,<br/><br/>

• organizovao susret između Nalogodavca i trećeg lica radi pregovaranja za zaključenje pravnog posla,<br/><br/>

• Nalogodavcu saopštio ime, broj telefona, telefaksa ili e-adrese trećeg lica zainteresovanog za zaključenje pravnog posla ili ako mu je saopštio tačnu lokaciju tražene nepokretnosti.<br/><br/><br/><br/>

 

Obaveze Nalogodavca<br/><br/>

• da obavesti Posrednika o  svim okolnostima koje su od značaja za obavljanje posredovanja,<br/><br/>

• da Posredniku da na uvid originale isprave koje dokazuju njegovo pravo na nepokretnosti koje je predmet prometa, odnosno da upozori Posrednika na sve upisane i neupisane terete koji postoje na nepokretnosti,<br/><br/>

• da osigura Posredniku i licu zainteresovanom za zaključenje pravnog posla razgledanje nepokretnosti, na dogovoreni način i u dogovoreno vreme,<br/><br/>

• da obavesti Posrednika o svim bitnim podacima o nepokretnosti, što posebno uključuje tačne podatke o ceni, lokaciji, strukturi i dr.,<br/><br/>

• da isplati Posredniku ugovorenu posredničku naknadu, i ako je to posebno ugovoreno, da nadoknadi posredniku druge troškove nastale tokom posredovanja,<br/><br/>

• da obavesti Posrednika pisanim putem o svim promenama u vezi sa posredovanim poslom, a posebno o promenama u vezi sa pravima na nepokretnosti, rokovima i cenom, a sve u roku od 3 (tri) dana od nastale promene,<br/><br/>

• da odmah obavesti Posrednika da je lice koje je preko Posrednika razgledalo nepokretnost pokazalo interesovanje da bez Posrednika zaključi Ugovor/Predugovor o kupoprodaji nepokretnosti, Ugovor o zakupu nepokretnosti, ili obavi neki drugi pravni posao koji je posledica rada Posrednika.<br/><br/><br/><br/>

 

<b>III OSTVARIVANJE PRAVA NA POSREDNIČKU NAKNADU</b><br/><br/>

Posrednik stiče pravo na posredničku naknadu u trenutku zaključenja ugovora za koji je posredovao, osim ako Posrednik i Nalogodavac nisu ugovorili da se pravo na posredničku naknadu stiče u momentu zaključenja predugovora za koji je Posrednik posredovao.<br/><br/>

Posrednik ne može da zahteva delimično plaćanje posredničke provizije unapred, odnosno pre zaključenja predugovora, odnosno ugovora, za koji je posredovao, u skladu sa prethodnim stavom.<br/><br/>

Iznos posredničke naknade, odnosno način određivanja iznosa posredničke naknade, kao i vrsta i visina troškova za dodatne usluge Posrednika, utvrđeni su Cenovnikom posredničkih usluga koji je sastavni deo ovih Opštih uslova poslovanja.<br/><br/>

Posrednik može da ugovori pravo na naknadu dodatnih troškova nužnih za izvršenje naloga, bez obzira na uspeh posredovanja, i zatraži da mu se unapred plate sredstva za određene izdatke, ako je to izričito navedeno u Ugovoru o posredovanju.<br/><br/>

Posrednik ima pravo na posredničku naknadu ako bračni, odnosno vanbračni drug, potomak ili roditelj lica sa kojim je posrednik doveo u vezu Nalogodavca, zaključi posredovani pravni posao.<br/><br/>

Ako nakon prestanka važenja Ugovora o posredovanju, na osnovu otkaza Nalogodavca, a u roku ne dužem od mesec dana od dana prestanka važenja ugovora Nalogodavac zaključi pravni posao koji je neposredna posledica posrednikovog posredovanja pre prestanka važenja Ugovora o posredovanju, dužan je da Posredniku plati ugovorenu posredničku naknadu u celini, osim ako je Ugovorom o posredovanju drugačije bilo ugovoreno.<br/><br/>

Ako pod uslovom i u roku iz prethodnog stava Nalogodavac zaključi pravni posao koji je u značajnoj meri rezultat Posrednikovog posredovanja pre prestanka važenja Ugovora o posredovanju, dužan je da plati Posredniku srazmernu posredničku naknadu, osim ako je Ugovorom o posredovanju drugačije bilo ugovoreno.<br/><br/>

Posrednik, odnosno Potposrednik nema pravo na naknadu za posredovanje ako sa Nalogodavcem u svoje ime, kao ugovorna strana, zaključi Ugovor koji je predmet posredovanja, odnosno ako takav Ugovor sa Nalogodavcem zaključi lice koje za Posrednika, odnosno za Potposrednika obavlja poslove posredovanja.<br/><br/><br/><br/>

 

<b>IV OPŠTE ODREDBE</b><br/><br/>

Na odnose između Nalogodavca i Posrednika koji nisu uređeni ovim Opštim uslovima poslovanja, a nisu ugovoreni Ugovorom o posredovanju, primenjuju se odredbe zakona koji regulišu ovu oblast i Zakona o obligacionim odnosima.</p>
            </div>
            </>
        );
    }
}

export default OuP;
