import React from 'react'
import {useContext} from 'react';
import {RealEstateContext} from '../context';
import Title from '../components/Title';
import {Helmet} from 'react-helmet';

const getUnique = (items, value) =>{
    return [...new Set(items.map(item => item[value]))]
}
export default function RESaleFilter({realEstates}) {
    const context = useContext(RealEstateContext);
    const {
       handleChange,
       type,
       location,
       price,
       minPrice,
       maxPrice,
       minSize,
       maxSize,
       purpose
    } = context;
    // get purpose types
    let purposes = getUnique(realEstates, 'purpose');
    purposes = ['Izaberite', ...purposes];
    purposes = purposes.map((item,index)=>{
      return (
      <option value={item} key={index}>{item}</option>
      );
    });
    
    // get unique types
    let types = getUnique(realEstates, 'type');

    //add all
    types = ['Izaberite',...types];
    //map to jsx
    types = types.map((item,index)=>{
        return (
        <option value={item} key={index}>{item}</option>
        );
    });
    let people = getUnique(realEstates, 'capacity');
    people = people.map((item,index)=>{
        return (
            <option key={index} value={item}>{item}</option>
        );
    });
    let locations = getUnique(realEstates, 'location');
    locations = ['Izaberite', ...locations];
    locations = locations.map((item,index)=>{
        return (
        <option key={index} value={item}>{item}</option>
        );
    });
    return (
        <section className="filter-container">
          <Helmet>
                <title>Pronađite nekretninu | Solar Nekretnine</title>
                <meta name="description" content="Brzo i jednostavno pronađite stan, kuću, poslovni prostor. Solar Nekretnine" />
                <link rel="canonical" href="https://solarnekretnine.com/nekretnine" />
            </Helmet>
           <Title title="Pronađite nekretninu"/>
           <form className="filter-form">
             {/* purpose */}
             <div className="form-group">
               <label htmlFor="type">Namena</label> 
               <select 
                 name="purpose" 
                 id="purpose" 
                 value={purpose} 
                 className="form-control" 
                 onChange={handleChange}>
                    {purposes}
                 </select>
             </div>
             {/* end purpose */}

             {/* select type */}
             <div className="form-group">
               <label htmlFor="type">Tip nekretnine</label> 
               <select 
                 name="type" 
                 id="type" 
                 value={type} 
                 className="form-control" 
                 onChange={handleChange}>
                    {types}
                 </select>
             </div>
             {/* end select type */}

             {/* select location */}
             <div className="form-group">
               <label htmlFor="location">Lokacija</label> 
               <select 
                 name="location" 
                 id="location" 
                 value={location} 
                 className="form-control" 
                 onChange={handleChange}>
                    {locations}
                 </select>
             </div>
             {/* end select location */}

              

             {/* RealEstate Price */}
             <div className="form-group">
               <label htmlFor="price">
                  Cena: {price} €
               </label> 
               <input type="range" name="price" min={minPrice} max={maxPrice} id="price" value={price} onChange={handleChange} className="form-control"/> 
             </div>
             {/* End RealEstate Price */}

             {/* RealEstate Size */}
             <div className="form-group">
               <label htmlFor="size">Dimenzija: m²</label>
               <div className="size-inputs">
                   <input type="number" name="minSize" id="size" value={minSize} onChange={handleChange} className="size-input"/>
                   <input type="number" name="maxSize" id="size" value={maxSize} onChange={handleChange} className="size-input"/>
               </div>
             </div><br/>
             {/* End RealEstate Size */}
           </form>
        </section>
    )
}
