import React, { Component } from 'react';
import {Helmet} from 'react-helmet';



class Pp extends Component {
    render() {
        return (
            <>
            <Helmet>
                <title>Politika privatnosti | Solar Nekretnine</title>
                <meta name="description" content="Politika privatnosti" />
                <link rel="canonical" href="https://solarnekretnine.com/pp" />
            </Helmet>
            <div className="hero-policy">
                <div className="banner-policy">
                    <h3>Politika privatnosti</h3>
                </div>
            </div>
            <div className="banner-policy-text">
            <p>
                Kao potencijalne klijente, kompanija za promet nekretnina West Properties d.o.o., želi da vas obavesti o tome kako će se vaši lični podaci koristiti i u koje svrhe, u skladu sa članom 15. Zakona o zaštiti podataka o ličnosti Republike Srbije.<br></br><br></br>

<b>1. Vrste podataka koje prikupljamo</b><br></br>

Podaci koje ćemo obrađivati su podaci koje nam Vi dostavljate ukoliko ste zainteresovani za kupovinu ili rentiranje nekretnine, kao i dodatne usluge koje West Properties d.o.o. pruža, a to podrazumeva sledeće:<br/><br/>

ime i prezime;<br></br>
naziv kompanije;<br></br>
e-mail adresa;<br></br>
broj mobilnog telefona;<br></br>
sadržaj koji nam prosleđujete (popunjavanjem forme “Ponudite nekretninu”)<br></br>
<b>2. Svrha prikupljanja i dalje obrade podataka</b><br></br>

Vaši lični podaci kojisu definisani pod tačkom 1. Ovog obaveštenja, biće prikupljeni ukoliko iskažete interesovanje za kupovinu, prodaju, rentiranje nepokretnosti u vezi sa objavljenim nepokretnostima na sajtu West Properties. Svrha prikupljanja ličnih podataka jeste ostvarivanje kontakta sa Vama, u vezi sa Vašim interesovanjem i razumevanjem Vaših potreba. Vaši lični podaci će biti upotrebljeni isključivo u gorepomenute svrhe, osim ukoliko ispunjenje obaveza propisanih zakonom ne zahteva drugačije.<br></br>

<b>3. Način korišćenja podataka</b><br></br>

Podaci koje nam dostavite koristiće se kroz prikupljanje, beleženje, kopiranje, pretraživanje, razvrstavanje, pohranjivanje, razdvajanje, menjanje, korišćenje, stavljanje na uvid, organizovanje, čuvanje i prilagođavanje prikupljenih podataka.<br></br>

<b>4. Informacije o korisnicima podataka</b><br></br>

West Properties d.o.o. Vaše podatke o ličnosti neće dati na korišćenje trećim licima, izuzev u slučaju kada tako nalažu propisi.<br></br>

<b>5. Obaveznost i pravni osnov obrade podataka</b><br></br>

Pravni osnov obrade podataka je Vaša saglasnost. Davanje saglasnosti za prikupljanje i obradu podataka je dobrovoljno i možete ga opozvati u svakom trenutku. U slučaju opoziva dužni ste da nadoknadite sve opravdane troškove i štetu koji nastanu u slučaju opoziva.<br></br>

<b>6. Vaša prava</b><br></br>

U skladu sa Zakonom, imate prava da na Vaš zahtev budete obavešteni vezano za obradu podataka, pravo na uvid u podatke koji se na Vas odnose kao i pravo da zahtevate njihovu kopiju. Pored navedenog, imate pravo da zahtevate ispravku, dopunu, ažuriranje, brisanje podataka, kao i prekid i privremenu obustavu obrade.<br></br>

Pravo na brisanje podataka imate ako:<br></br>

svrha obrade nije jasno određena;<br></br>
je svrha obrade izmenjena, a nisu ispunjeni uslovi za obradu za tu izmenjenu svrhu;<br></br>
je svrha obrade ostvarena, odnosno podaci više nisu potrebni za ostvarivanje svrhe;<br></br>
je način obrade nedozvoljen;<br></br>
podatak spada u broj i vrstu podataka čija je obrada nesrazmerna svrsi;<br></br>
je podatak netačan, a ne može se putem ispravke zameniti tačnim;<br></br>
se podatak obrađuje bez pristanka ili ovlašćenja zasnovanog na zakonu i u drugim slučajevima kada se obrada ne može vršiti u skladu sa odredbama Zakona.<br></br>
Pravo na prekid i privremenu obustavu obrade, imate ako ste osporili tačnost, potpunost i ažurnost podataka, kao i pravo da se ti podaci označe kao osporeni, dok se ne utvrdi njihova tačnost, potpunost i ažurnost.<br></br>

<b>8. Ostale informacije bitne za obradu Vaših podataka</b><br></br>

Vaši podaci će se obrađivati i čuvati dok postoji potreba i biće tretirani kao poverljive informacije. Pristup njima imaće samo lica koja, s obzirom na opis posla koji obavljaju, treba da budu upoznati sa Vašim podacima o ličnosti i to samo u obimu koji je neophodan za obavljanje njihovih poslova.<br></br>

<b>9. Mere zaštite</b><br></br>

West Properties će preduzeti odgovarajuće organizacione, tehničke i kadrovske mere kako bi zaštitilo Vaše podatke o ličnosti u skladu s važećim zakonima vezano za zaštitu podataka o ličnosti kao i standardima koji se primenjuju na polju obezbeđenja fizičke i elektronske sigurnosti podataka.<br></br>

Vaši podaci o ličnosti će biti tretirani kao poverljive informacije. Pristup njima imaće samo lica koja, s obzirom na opis posla koji obavljaju u Društvu, treba da budu upoznati sa Vašim podacima o ličnosti i to samo u obimu koji je neophodan za obavljanje njihovih poslova.<br></br>

<b>10. Ostalo</b><br></br>

Ukoliko imate pitanja ili zahteve u vezi sa obradom Vaših podataka o ličnosti naši kontakt podaci su<br></br>

West Properties d.o.o.<br></br>

Baba Višnjina 38<br></br>

11000 Beograd<br></br><br></br>

 

E-mail: office@solarnekretnine.rs<br></br><br></br>

 

SAGLASNOST<br></br>

Ovim potvrđujem da sam pročitao/la i razumeo/la ovo obaveštenje o obradi podataka o ličnosti te ovim dajem saglasnost da West Properties d.o.o. može obrađivati moje podatke o ličnosti u skladu sa ovim obaveštenjem i važećim zakonima i propisima.
                </p>
            </div>
             
            </>
        );
    }
}

export default Pp;
