import React, { Component } from 'react';
import defaultBcg from '../images/room-1.jpeg';
import Hero from '../components/Hero';
import Banner from '../components/Banner';
import {Link} from 'react-router-dom';
import {RealEstateContext} from '../context';
import StyledHero from '../components/StyledHero';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import SlideShow from 'react-image-show';
import euro from '../images/euro.png';
import {Helmet} from 'react-helmet';

import {
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  ViberShareButton,
  WhatsappShareButton,
  EmailShareButton,
  ViberIcon,
  FacebookIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";



//import ImageGallery from 'react-image-gallery';

class DetailPageSale extends Component {
    constructor(props){
     super(props)
     //console.log(this.props);
     this.state ={
         slug:this.props.match.params.slug,
         defaultBcg,
         photoIndex: 0,
         isOpen:false
     };
    }
    static contextType = RealEstateContext;
    //componentDidMount(){}

    render() {
        const {getRealEstate} = this.context;
        const realEstate = getRealEstate(this.state.slug);
        if(!realEstate){
            return <div className="error">
              <h3>Ova nekretnina ne postoji...</h3>
              <Link to='/sale' className="btn-primary">
                 Vrati se nazad 
              </Link>
            </div>
        }
        const {name,
               description,
               size,
               price,
               position,
               type,
               location,
               extras,
               images,
               slug} = realEstate;  
        const { photoIndex, isOpen } = this.state;
        let url = `https://solarnekretnine.com/nekretnine/${slug}`;
        return (
            <>
            <Helmet>
                <title>{location} | {type} | Solar Nekretnine</title>
                <meta name="description" content="Pronađite vaš dom iz snova | Solar Nekretnine | Mesto odabranih nekretnina" />
                <link rel="canonical" href="https://solarnekretnine.com/nekretnine/:slug" />
            </Helmet>
            <section className="realestate-room">
  
            <div>
            <SlideShow
              images={images}
              width="920px"
              imagesWidth="800px"
              imagesHeight="450px"
              imagesHeightMobile="56vw"
              thumbnailsWidth="920px"
              thumbnailsHeight="12vw"
              indicators thumbnails fixedImagesHeight
              onImageClick = {() => this.setState({isOpen: true})}
              arrows = {false}
            >
               
                </SlideShow>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
      
      <article className="info">
      <div className="locationiko"><div className="locationimg"></div><h4> {location} </h4></div>
      <div className="typeiko"><div className="typeimg"></div><h4> {type} </h4></div>
      <div className="euro"><div className="money"></div><h4>{price} €</h4></div>
      <div className="sizeiko"><div className="sizeimg"></div><h4> {size} m²</h4></div>
                  </article>
                <div className="single-realestate-info">
                  <article className="desc">
                      <h3>Detalji</h3>
                      <p>{description}</p><br/><br/>
                      <p>Podelite nekretninu:</p> <br/>
                        
                      <div>
                  <FacebookShareButton style={{paddingRight:'10px'}} url={url} quote="Bio Field Care Flasteri Srbija"><FacebookIcon size={32} round={true}/></FacebookShareButton>
                  <FacebookMessengerShareButton style={{paddingRight:'10px'}} url={url}><FacebookMessengerIcon size={32} round={true}/></FacebookMessengerShareButton>
                  <ViberShareButton style={{paddingRight:'10px'}} url={url} ><ViberIcon size={32} round={true}/></ViberShareButton>
                  <WhatsappShareButton style={{paddingRight:'10px'}} url={url} ><WhatsappIcon size={32} round={true}/></WhatsappShareButton>
                  <EmailShareButton url={url} ><EmailIcon size={32} round={true}/></EmailShareButton>
                  </div>
                      
                  </article>
                  <article className="maps">
                  <MapContainer center={position} zoom={13} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                   <Marker position={position}>
                   <Popup>
                   </Popup>
                   </Marker>
                   </MapContainer>
                  </article>
                  
                </div>
            </section>
            </>
        );
    }
}

export default DetailPageSale;