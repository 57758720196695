import React from 'react';
//import Hero from '../components/Hero';
//import Banner from '../components/Banner';
//import {Link} from 'react-router-dom';
import RESaleContainer from '../components/RESaleContainer';

const Sale = () => {
    return (
        <>
        <RESaleContainer/>
        </>
    )
}

export default Sale;