import React, { Component } from 'react';
import logo from '../images/solar.png';
import {CgMenu} from 'react-icons/cg';
import {Link} from 'react-router-dom';

class NavBar extends Component {

    state = {
        isOpen: false
    };
    handleToggle = () => {
        this.setState({isOpen:!this.state.isOpen});
    };

    closeNavbar = () => {
        this.setState({isOpen:false});
    }

    render() {
        return (
            <nav className="navbar">
                <div className="nav-center">
                    <div className="nav-header">
                        <Link to="/">
                            <img src={logo} alt="Solar Nekretnine"/>
                        </Link>
                        <button 
                         type="button" 
                         className="nav-btn" 
                         onClick={this.handleToggle}>
                            <CgMenu className="nav-icon"/>
                        </button>
                    </div>
                    <ul 
                    className={this.state.isOpen ? "nav-links show-nav" : "nav-links"}>
                        <li>
                            <Link to="/" onClick={this.closeNavbar}>Naslovna</Link>
                        </li>
                        <li>
                            <Link to="/nekretnine" onClick={this.closeNavbar}>Pretraga nekretnina</Link>
                        </li>
                        <li>
                            <Link to="/onama" onClick={this.closeNavbar}>O nama</Link>
                        </li>
                        <li>
                            <Link to="/kontakt" onClick={this.closeNavbar}>Kontakt</Link>
                        </li>
                        <li className="right">
                            <Link to="/kontakt" >+382 69 189 000</Link>
                        </li>
                        

                    </ul>
                   
                       
                    
                </div>
            </nav>
        );
    }
}

export default NavBar;