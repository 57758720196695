import React, { Component } from 'react';
import logo from '../images/solar.png';
import {Link} from 'react-router-dom';
import {AiFillFacebook} from 'react-icons/ai';
import {AiFillInstagram} from 'react-icons/ai';
import {AiFillYoutube} from 'react-icons/ai';

class Footer extends Component {
    state={
        footerDetails1: [
            {
                //icon: <FaCocktail/>,
                adress: 'Bulevar Svetog Petra Cetinjskog b.b',
                phone1: '+38269189000',
                phone2: '+38269333992',
                email: 'solarnekretnine@gmail.com'
            }
        ],
       footerDetails2: [
           {
               link1: 'Naslovna',
               link2: 'Pronađite nekretninu',
               link3: 'O nama',
               link4: 'Kontakt'
           }
       ],
       footerDetails3: [
        {
            link1: 'Opšti uslovi poslovanja',
            link2: 'Uslovi korištenja sajta',
            link3: 'Politika privatnosti'
        }
    ]
    }

    render() {
        
        return (
            <>
            <div className="footer">
                <div className="footer-center">
                {this.state.footerDetails1.map((item, index) => {
                  return <article key={index} className="footer-detail">
                      <p>Adresa: {item.adress}</p>
                      <p>Telefon1: {item.phone1}<br/><br/>
                         Telefon2: {item.phone2}
                      </p>
                      <p>Email: {item.email}</p>
                      <ul className="footer-links2">
                        <li>
                            <Link to="/"><AiFillFacebook className="facebook"/>{item.link1}</Link>
                        </li>
                        <li>
                            <Link to="/"><AiFillInstagram/></Link>
                        </li>
                        <li>
                            <Link to="/" ><AiFillYoutube/></Link>
                        </li>
                    </ul>
                  </article>
                })}
             
                </div>
                <div className="footer-center">
                {this.state.footerDetails2.map((item, index) => {
                  return <article key={index} className="footer-detail">
                      <ul className="footer-links1">
                        <li>
                            <Link to="/">{item.link1}</Link>
                        </li>
                        <li>
                            <Link to="/nekretnine">{item.link2}</Link>
                        </li>
                        <li>
                            <Link to="/onama" >{item.link3}</Link>
                        </li>
                        <li>
                            <Link to="/kontakt" >{item.link4}</Link>
                        </li>

                    </ul>
                  </article>
                })}
             
                </div>
                <div className="footer-center">
                {this.state.footerDetails3.map((item, index) => {
                  return <article key={index} className="footer-detail">
                      <ul className="footer-links3">
                        <li>
                            <Link to="/oup">{item.link1}</Link>
                        </li>
                        <li>
                            <Link to="/uks">{item.link2}</Link>
                        </li>
                        <li>
                            <Link to="/pp" >{item.link3}</Link>
                        </li>
                    </ul>
                  </article>
                })}
             
                </div>
            </div>
            <div className="indaco">
            
            </div>
            </>
        );
    }
}

export default Footer;