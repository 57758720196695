import React from 'react';
import RESaleFilter from './RESaleFilter';
import RESaleList from './RESaleList';
import {withRealEstateConsumer} from '../context';
import Loading from './Loading';

function RealEstateContainer({context}){
 const {loading,realEstates, sortedRE} = context;
 if(loading){
    return <Loading/>  
 }
  return (
    <>
      <RESaleFilter realEstates={realEstates} />
      <RESaleList realEstates={sortedRE}/>
    </>
  );
}

export default withRealEstateConsumer(RealEstateContainer)









/* import React from 'react';
import RESaleFilter from './RESaleFilter';
import RESaleList from './RESaleList';
import {RealEstateConsumer} from '../context';
import Loading from './Loading';

export default function RESaleContainer() {
    return (
        <RealEstateConsumer>
{value => {
        const {loading,sortedRE,realEstates} = value;
        if(loading){
          return <Loading/>  
        }
        return (
            <div>
            Pozdrav od Solara
            <RESaleFilter realEstates={realEstates} />
            <RESaleList realEstates={sortedRE}/>
        </div>
        );
    }
}
        </RealEstateConsumer>
    );
}
*/