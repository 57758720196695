import React from 'react';
import tondach from '../images/tondach.png';
import solaris from '../images/solaris.png';



const Advertise = () => {
    return (
        <div className="advertise-container">
            <div className="advertise1">
                <img src={tondach}/>

            </div>
            <div className="advertise2">
            <img src={solaris}/>
            </div>
        </div>
    )
}

export default Advertise;