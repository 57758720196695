import React, { Component } from 'react';
import { MDBContainer } from 'mdbreact';
import {Helmet} from 'react-helmet';




class UkS extends Component {
    render() {
        return (
            <>
            <Helmet>
                <title>O nama | Solar Nekretnine</title>
                <meta name="description" content="Saznajte nešto više o nama, Solar Nekretnine" />
                <link rel="canonical" href="https://solarnekretnine.com/onama" />
            </Helmet>
            <div className="hero-policy">
                <div className="banner-policy">
                    <h2>O nama</h2>
                </div>
            </div>
            <div className="banner-policy-text">
            <p>U kompaniji SolarSystem Vi ste broj jedan. Bez obzira da li ste vlasnik nekretnine, zakupac ili kupac, cenimo vaše poslovanje i pružićemo vam individualnu pažnju i uslugu koju zaslužujete.<br/><br/>
                Verujemo u strogi etički kodeks. Verujemo u integritet, posvećenost i profesionalni stav.<br/><br/>
                Spajajući višegodišnje iskustvo i moderne tehnologije, posredujemo u prometu nekretninama kako bismo zadovoljili potrebe naših klijenata.<br/><br/>
                Pružamo uvid i opsežno znanje kupcima bilo koje vrste nekretnina kako bismo pomogli u izboru. </p>
                </div>
            </>
        );
    }
}

export default UkS;
