import React, { Component } from 'react';
import {Helmet} from 'react-helmet';


class UkS extends Component {
    render() {
        return (
            <>
            <Helmet>
                <title>Uslovi korištenja sajta | Solar Nekretnine</title>
                <meta name="description" content="Uslovi korištenja sajta" />
                <link rel="canonical" href="https://solarnekretnine.com/uks" />
            </Helmet>
            <div className="hero-policy">
                <div className="banner-policy">
                    <h3>Uslovi korištenja sajta</h3>
                </div>
            </div>
            <div className="banner-policy-text">
            <p>
                Hvala što ste posetili naš internet sajt. Vaš pristup, kao i korišćenje ovog našeg internet sajta (u daljem tekstu: internet sajt/Sajt) podleže ovim uslovima korišćenja i važećim zakonskim propisima koji uređuju ovu oblast.<br/><br/>

                Sajt www.nekretninesolar.000webhostapp.com u vlasništvu je kompanije West Properties d.o.o. koja njime i upravlja. Pristupom i korišćenjem internet sajta, prihvatate bez ograničenja, ove uslove korišćenja. Korišćenjem sajta ili, gde je to moguće, tako što ćete potvrditi da prihvatate ili se slažete sa Uslovima korišćenja kada je ova opcija na raspolaganju, Vi prihvatate i potvrđujete da ste saglasni i da ćete se pridržavati ovih Uslova korišćenja i naše politike privatnosti, i koja je putem upućivanja inkorporirana u ove Uslove korišćenja. Ako ne prihvatate ove Uslove korišćenja ili Politiku privatnosti, ne smete pristupiti Sajtu niti koristiti Sajt.<br/><br/><br/><br/>


                Vlasništvo sadržaja<br/><br/>

                Internet sajt i svi tekstovi, logotipi, grafika, slike, audio materijal i ostali materijal na ovom internet sajtu (u daljem tekstu: Sadržaj), jesu autorsko pravo ili vlasništvo West Properties ili su na internet sajt postavljeni uz dozvolu vlasnika ili ovlašćenog nosioca prava. Korišćenje Sadržaja, osim na način opisan u ovim uslovima korišćenja, bez pisane dozvole vlasnika Sadržaja je strogo zabranjeno. West Properties će zaštiti svoja autorska prava, svoja prava intelektualne svojine i ostala srodna prava, kao i druga prava, u najvećoj meri dozvoljenoj zakonom, uključujući i krivično gonjenje.<br/><br/><br/><br/>


                Vaša upotreba internet sajta<br/><br/>

                Sadržaj internet sajta može sadržati netačne podatke ili štamparske greške. Promene na internet sajtu se mogu napraviti periodično u bilo kom trenutku i bez obaveštenja. Međutim, West Properties se ne obavezuje da redovno ažurira informacije sadržane na ovom internet sajtu. Takođe, West Properties ne garantuje da će internet sajt funkcionisati bez prekida ili grešaka, da će nedostaci biti blagovremeno ispravljani ili da je internet sajt kompatibilan sa vašim hardverom ili softverom. <br/><br/>

                Možete koristiti Sajt samo u zakonite svrhe, a u skladu sa ovim Uslovima korišćenja. Vi se slažete da nećete koristiti Sajt:<br/><br/>

                Na način koji krši bilo koji važeći savezni, lokalni ili međunarodni zakon ili propis, uključujući, bez ograničenja, bilo kakav oblik zloupotrebe kreditnih kartica ili drugih oblika elektronskih plaćanja;<br/><br/>

                Za prenos, odnosno slanje bilo kakvog promotivnog materijala, uključujući i „junk mail“, „lančano pismo“, „spam“ ili bilo šta slično;<br/><br/>

                Da se predstavite ili pokušate da se predstavite u ime Kompanije, kao zaposleni Kompanije, drugi korisnik ili bilo koje drugo lice ili entitet (uključujući, bez ograničenja, korišćenjem i-mejl adresa ili korisničkog povezanih sa bilo čim sto je napred navedeno);<br/><br/>

                Da postupate na način koji ograničava ili sprečava bilo čije korišćenje Sajta, ili koji, određeno sa naše strane, može da ošteti Kompaniju ili korisnike sajta i izloži ih odgovornosti.<br/><br/>

                Pored toga, Vi ste saglasni da nećete:<br/><br/><br/><br/>


                Koristiti Sajt na bilo koji način koji bi mogao da onesposobi, preoptereti, ošteti, ili pogorša Sajt ili onemogući korišćenje Sajta od strane bilo kog drugog lica, uključujući i njihovu sposobnost da se uključe u aktivnosti u realnom vremenu putem Sajta;<br/><br/>

                Koristiti robote, paukove ili druge automatske uređaje, načine ili sredstva da pristupite Sajtu u bilo koju svrhu, uključujući i monitoring i kopiranje bilo kog materijala na Sajtu;<br/><br/>

                Koristiti manuelne procese da biste nadgledali ili kopirali materijal na Sajtu ili u bilo koju drugu neovlašćenu svrhu bez našeg prethodnog pisanog odobrenja;<br/><br/>

                Koristiti bilo kakav uređaj, softver ili metodu koji ometaju pravilan rad Sajta;<br/><br/>

                Ubaciti viruse, trojanske konje, crve, logičke bombe ili drugi materijal koji je zlonameran ili tehnološki štetan;<br/><br/>

                Pokušati da dobijete neovlašćeni pristup, ometati, oštetiti ili poremetiti neke delove Sajta, servera na kome se čuva Sajt, ili bilo kog servera, računara ili baze podataka koji su povezani sa Sajtom;<br/><br/>

                Napasti Sajt putem napada uskraćivanja servisa ili distribuiranih napada uskraćivanja servisa;<br/><br/>

                Na drugi način pokušati da onemogućite pravilan rad Sajta.<br/><br/><br/><br/>


                OSLANJANJE NA OBJAVLJENE INFORMACIJE<br/><br/>

                Informacije predstavljene na Sajtu ili putem njega dostupne su isključivo u opšte informativne svrhe. Ne garantujemo tačnost, potpunost ili korisnost ovih informacija. Bilo kakvo oslanjanje na takve informacije je strogo na Vaš sopstveni rizik. Odričemo se bilo kakve obaveze i odgovornosti koje su posledica Vašeg oslanjanja na takav materijal ili bilo kog drugog posetioca na sajtu, ili bilo koga ko može da bude obavešten o njegovom sadržaju.<br/><br/>

                Izuzeće od odgovornosti<br/><br/>

                Internet sajt koristite na sopstveni rizik. West Properties nije odgovoran za materijalnu ili nematerijalnu štetu, direktnu ili indirektnu koja nastane iz korišćenja ili je u nekoj vezi sa korišćenjem internet sajta ili njegovog Sadržaja.<br/><br/><br/><br/>


                Linkovi sa internet sajtovima trećih lica<br/><br/>

                Internet sajt može sadržati linkove drugih internet sajtova čiji vlasnik ili korisnik nije West Properties. Takvi linkovi su obezbeđeni isključivo da bi Vama pružili što više informacija. West Properties ne kontroliše i nije odgovoran za rad, sadržaj, politiku privatnosti ili bezbednost ovih sajtova. West Properties ne kontroliše sadržaj ili proizvode ili usluge dostupne na ovim internet sajtovima. Ako uspostavite vezu sa takvim internet sajtovima preko linka na našem internet sajtu, to činite na sopstveni rizik i bez saglasnosti West Properties.<br/><br/><br/><br/>


                Softver dostupan na ovom internet sajtu<br/><br/>

                Prava intelektualne svojine i ostala prava na bilo koji softver koji je dostupan za preuzimanje sa internet sajta (u daljem tekstu Softver), pripadaju West Properties. Vaša upotreba Softvera podleže uslovima sporazuma o licenci koji prate ili su uključeni u Softver. West Properties ne prihvata odgovornost za sadržinu pravila korišćenja ili ostalih dokumenata koji su uključeni u Softver ili ga prate. Nemojte instalirati niti koristiti Softver ako se ne slažete sa takvim sporazumima o licenci. Kada preuzimate Softver sa internet sajta, to činite na sopstveni rizik.<br/><br/><br/><br/>


                Izmena uslova korišćenja<br/><br/>

                West Properties može u svakom trenutku izmeniti ove uslove korišćenja tako što će ažurirati ovaj tekst. Vi ćete automatski biti obavezani novim uslovima korišćenja sadržanim u izmenama, te bi iz tog razloga trebalo da periodično posetite ovu stranicu radi upoznavanja sa trenutno važećim uslovima korišćenja.
                </p>

            </div>
            
            </>
        );
    }
}

export default UkS;
